import React, { useEffect, useRef } from "react";

type Props = {
	message: string;
	set_message: Function;
	onEnter: Function;
	isDisabled: boolean;
};

const ResizingInput: React.FC<Props> = ({ message, set_message, onEnter, isDisabled }) => {
	const text_area_ref = useRef<HTMLTextAreaElement>(null);

	useEffect(() => {
		if (text_area_ref.current) {
			const current_scroll_height: number = text_area_ref.current.scrollHeight;

			text_area_ref.current.style.overflow = current_scroll_height >= 192 ? "auto" : "hidden";
			text_area_ref.current.style.height = "auto";
			text_area_ref.current.style.height = `${text_area_ref.current.scrollHeight}px`;
		}
	}, [message]);

	return (
		<textarea
			ref={text_area_ref}
			className={`outline-noneresize-none my-2 max-h-[192px] w-full resize-none overflow-hidden bg-transparent text-off-white focus:outline-none ${
				isDisabled ? "cursor-not-allowed" : ""
			}`}
			value={message}
			onChange={(event) => !isDisabled && set_message(event.target.value)}
			onKeyDown={(event) => {
				if (!isDisabled) {
					if (event.key === "Enter" && event.shiftKey) {
						event.preventDefault();
						set_message(message + "\n");
					} else if (event.key === "Enter") {
						event.preventDefault();
						if (text_area_ref.current) text_area_ref.current.style.height = "auto";
						onEnter();
					}
				}
			}}
			placeholder={isDisabled ? "Waiting for response..." : "Type a message..."}
			rows={1}
			disabled={isDisabled}
		/>
	);
};

export default ResizingInput;
