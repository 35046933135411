export enum EDIT_ACTIONS {
	UPDATE,
	NONE,
}

export enum EDIT_FILE_ACTIONS {
	DELETE,
	WRITE,
	NONE,
}

export enum MESSAGE_TYPE {
	SERVER,
	CLIENT,
}

export enum MODEL_TYPE {
	RAG = "RAG",
	LLM = "LLM",
}

export enum SETTINGS {
	NONE,
	AUTH,
	MODELS,
	DEFAULT,
}

export enum MODEL_UPDATE_FIELDS {
	NAME = "name",
	DESC = "description",
	IMG = "img_ref",
}
