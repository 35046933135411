import React from "react";
import ReactMarkdown from "react-markdown";
import { PrismLight as SyntaxHighlighter } from "react-syntax-highlighter";
import { atomDark } from "react-syntax-highlighter/dist/esm/styles/prism";
import type { SyntaxHighlighterProps } from "react-syntax-highlighter";
import { CodeProps } from "react-markdown/lib/ast-to-react";

import { MESSAGE_TYPE } from "../../../types/enums";

type Props = {
	type: MESSAGE_TYPE;
	text: string;
};

function MarkdownMessage({ type, text }: Props) {
	const container_class = type === MESSAGE_TYPE.CLIENT ? "my-2 w-full flex justify-end" : "my-2 w-full";

	const message_class =
		type === MESSAGE_TYPE.CLIENT ? "max-w-[80%] rounded-xl bg-medium-gray p-4" : "w-full rounded-xl bg-dark-gray p-4";

	return (
		<div className={container_class}>
			<div className={message_class}>
				<ReactMarkdown
					components={{
						code({ node, inline, className, children, ...props }: CodeProps) {
							const match = /language-(\w+)/.exec(className || "");
							return !inline && match ? (
								<SyntaxHighlighter
									{...(props as SyntaxHighlighterProps)}
									style={atomDark}
									language={match[1]}
									PreTag="div"
								>
									{String(children).replace(/\n$/, "")}
								</SyntaxHighlighter>
							) : (
								<code className={className} {...props}>
									{children}
								</code>
							);
						},
						h2: ({ children }: { children: React.ReactNode }) => (
							<h2 className="mb-2 mt-4 text-xl font-bold">{children}</h2>
						),
						h3: ({ children }: { children: React.ReactNode }) => (
							<h3 className="mb-1 mt-3 text-lg font-semibold">{children}</h3>
						),
						p: ({ children }: { children: React.ReactNode }) => <p className="my-2">{children}</p>,
						ul: ({ children }: { children: React.ReactNode }) => (
							<ul className="my-2 list-inside list-disc">{children}</ul>
						),
						li: ({ children }: { children: React.ReactNode }) => <li className="my-1">{children}</li>,
					}}
				>
					{text}
				</ReactMarkdown>
			</div>
		</div>
	);
}

export default MarkdownMessage;
