import React from "react";

import PDFIcon from "../../../assets/icons/gray-pdf-icon.svg";
import XIcon from "../../../assets/icons/x-icon.svg";

type Props = {
	files: Array<LocalFile>;
	set_files: Function;
};

const FilePreviews: React.FC<Props> = ({ files, set_files }) => {
	const on_remove_file = (remove_idx: number) =>
		set_files((existing_files: Array<LocalFile>) => existing_files.filter((_, idx) => idx !== remove_idx));

	const set_preview_box = (preview: string, data: any, idx: number) => {
		const { name, type } = data;

		switch (type) {
			case "application/pdf": {
				return (
					<div className="flex h-full w-[150px] flex-row items-center pr-2">
						<img className="mr-2 h-[40px] w-[40px]" src={PDFIcon} alt={"PDF Document"} />
						<p className="truncate font-bold text-medium-gray">{name}</p>
					</div>
				);
			}
			default: {
				return <img className="h-full w-full" src={preview} alt={`File Preview ${idx}`} />;
			}
		}
	};

	return (
		<div
			className="row max-w-[650px] items-center overflow-x-scroll py-4"
			style={{
				display: files.length ? "flex" : "none",
			}}
		>
			{files.map((file: LocalFile, idx: number) => {
				const { data, preview } = file;

				return (
					<div key={idx} className="relative mx-4 h-[50px] w-[200px] rounded-xl bg-light-gray p-2">
						<img
							className="absolute right-[-10px] top-[-10px] z-10 h-[20px] w-[20px] rounded-full bg-light-gray p-[2px] hover:cursor-pointer hover:bg-off-white"
							src={XIcon}
							alt={"Remove File"}
							onClick={() => on_remove_file(idx)}
						/>
						{set_preview_box(preview, data, idx)}
					</div>
				);
			})}
		</div>
	);
};

export default FilePreviews;
