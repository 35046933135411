import { useEffect, useRef } from "react";

import Loader from "../../../common/components/loader";
import MarkdownMessage from "./markdown_formatter";

import { MESSAGE_TYPE } from "../../../types/enums";

import MAIN_LOGO from "../../../assets/images/main-logo.png";

type Props = {
	message_history: Array<{ type: MESSAGE_TYPE; text: string }>;
	waiting_for_response: boolean;
};

function ChatMessages({ message_history, waiting_for_response }: Props) {
	const latest_user_message_ref = useRef<HTMLDivElement>(null);
	const max_client_idx = useRef<number>(0);

	if (message_history.length && message_history[message_history.length - 1].type === MESSAGE_TYPE.CLIENT)
		max_client_idx.current = message_history.length - 1;

	const scroll_to_latest_user_message = () => {
		if (latest_user_message_ref.current) {
			latest_user_message_ref.current.scrollIntoView({ behavior: "smooth", block: "start" });
		}
	};

	useEffect(() => scroll_to_latest_user_message(), [message_history]);

	return (
		<div className="relative h-full w-full">
			<div
				className="absolute inset-0 space-y-4 overflow-y-auto pr-4"
				style={{
					scrollbarWidth: "thin",
					scrollbarColor: "rgba(155, 155, 155, 0.5) transparent",
				}}
			>
				{message_history.map((message, idx) => (
					<div
						key={idx}
						ref={idx === max_client_idx.current ? latest_user_message_ref : null}
						className={`flex ${message.type === MESSAGE_TYPE.SERVER ? "items-start" : "justify-end"}`}
					>
						{message.type === MESSAGE_TYPE.SERVER && (
							<div className="relative mr-4 h-12 w-12 flex-shrink-0">
								<div className="absolute left-0 top-0 flex h-14 w-14 items-center justify-center overflow-hidden rounded-full border border-gray-600 bg-dark-gray">
									<div className="relative h-9 w-9">
										<img
											src={MAIN_LOGO}
											alt="AI Assistant"
											className="absolute inset-0 h-full w-full object-contain"
										/>
									</div>
								</div>
							</div>
						)}
						<div className="flex-grow pt-1">
							<MarkdownMessage type={message.type} text={message.text} />
						</div>
					</div>
				))}
				<Loader loading={waiting_for_response} />
			</div>
		</div>
	);
}

export default ChatMessages;
