import { useState } from "react";
import FilePreviews from "../../../common/components/file_previews";
import ResizingInput from "./resizing_input";
import CarrotIcon from "../../../assets/icons/gray-carrot-icon.svg";

type Props = {
	onClick: (message: string, files: Array<LocalFile>) => void;
	isDisabled: boolean;
};

function InputBox({ onClick, isDisabled }: Props) {
	const [client_message, set_client_message] = useState<string>("");
	const [upload_files, set_upload_files] = useState<Array<LocalFile>>([]);
	// const [display_upload, set_display_upload] = useState<boolean>(false);

	// const append_file_list = (new_file: LocalFile) => {
	// 	set_upload_files((upload_files: any) => [...upload_files, new_file]);

	// 	if (display_upload) set_display_upload(false);
	// };
	const on_send = () => {
		if (!isDisabled && client_message.trim() !== "") {
			onClick(client_message, upload_files);
			set_client_message("");
			set_upload_files([]);
		}
	};

	return (
		<div
			className={`flex w-full flex-row items-end justify-between rounded-xl bg-medium-gray p-2 ${
				isDisabled ? "opacity-50" : ""
			}`}
		>
			{/* <div className="relative h-[40px] w-[40px]">
				<img
					className="h-full w-full hover:cursor-pointer"
					src={FileUploadIcon}
					alt="Upload File"
					onClick={() => set_display_upload(!display_upload)}
				/>
				<FileUpload display_upload={display_upload} onUpload={append_file_list} onExit={() => set_display_upload(false)} />
			</div> */}
			<div className="mx-2 flex max-h-[500px] w-full flex-col items-center">
				<FilePreviews files={upload_files} set_files={set_upload_files} />
				<ResizingInput
					message={client_message}
					set_message={set_client_message}
					onEnter={() => on_send()}
					isDisabled={isDisabled}
				/>
			</div>
			<img
				className={`h-[40px] w-[40px] rounded-md rounded-xl bg-light-gray p-[2px] ${
					isDisabled ? "cursor-not-allowed opacity-50" : "hover:cursor-pointer hover:bg-off-white"
				}`}
				onClick={() => !isDisabled && on_send()}
				src={CarrotIcon}
				alt="Send Message"
			/>
		</div>
	);
}

export default InputBox;
