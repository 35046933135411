export const message_parser = (msg: string) => {
	if (msg === "Welcome to your Research Assistant! How can I help you today?") return msg;

	try {
		const msg_obj = JSON.parse(msg);
		let markdown = "";

		if (msg_obj.answer) {
			markdown += `## Answer\n\n${msg_obj.answer}\n\n`;
		}

		if (msg_obj.sources && msg_obj.sources.length > 0) {
			markdown += "## Sources\n\n";
			msg_obj.sources.forEach((source: any, index: number) => {
				markdown += `### Source ${index + 1}\n\n`;
				markdown += `- **Name:** ${source.content || "N/A"}\n`;
				markdown += `- **File:** \`${truncate_filename(source.metadata.source, 50)}\`\n`;
				markdown += `- **Number of Chapters:** ${source.metadata.num_chapters || "N/A"}\n`;
				markdown += `- **Type:** ${source.metadata.type || "N/A"}\n\n`;
				markdown += `---\n\n`; // Add a horizontal line for visual separation
			});
		}

		return markdown.trim();
	} catch (error) {
		console.error("Error parsing message:", error);
		return msg; // Return original message if parsing fails
	}
};

export const truncate_filename = (filename: string, maxLength: number) => {
	if (filename.length <= maxLength) return filename;
	const extension = filename.split(".").pop();
	const nameWithoutExtension = filename.slice(0, -(extension?.length || 0) - 1);
	return `${nameWithoutExtension.slice(0, maxLength - 3)}...${extension}`;
};
