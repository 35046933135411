import { useEffect, useState } from "react";

import MAIN_LOGO from "../../../assets/images/main-logo.png";

type Props = {
	loading: boolean;
};

const Loader = ({ loading }: Props) => {
	const [dot_count, set_dot_count] = useState(1);

	useEffect(() => {
		if (loading) {
			const interval = setInterval(() => {
				set_dot_count((prev_count) => (prev_count % 3) + 1);
			}, 500);

			return () => clearInterval(interval);
		}
	}, [loading]);

	if (loading)
		return (
			<div className="w-full">
				<div className="flex items-start">
					<div className="relative mr-4 h-12 w-12 flex-shrink-0" style={{ marginTop: "-8px" }}>
						<div className="absolute left-0 top-0 flex h-14 w-14 items-center justify-center overflow-hidden rounded-full border border-gray-600 bg-dark-gray">
							<div className="relative h-9 w-9">
								<img src={MAIN_LOGO} alt="AI Assistant" className="absolute inset-0 h-full w-full object-contain" />
							</div>
						</div>
					</div>
					<div className="mt-1 flex items-center justify-center rounded-2xl bg-dark-gray px-4 py-2">
						<span className="text-6xl text-off-white">{".".repeat(dot_count)}</span>
					</div>
				</div>
			</div>
		);

	return null;
};

export default Loader;
