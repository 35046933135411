import { useEffect, useState } from "react";

import ChatMessages from "./chat_messages";
import InputBox from "./input_box";

import { message_parser } from "./utils";

import { MESSAGE_TYPE } from "../../types/enums";

function Chat() {
	const [message_history, set_message_history] = useState<Array<{ type: MESSAGE_TYPE; text: string }>>([]);
	const [ws, set_ws] = useState<WebSocket | null>(null);
	const [waiting_for_response, set_waiting_for_response] = useState<boolean>(false);

	const on_send = (message: string, upload_files: Array<LocalFile>) => {
		set_message_history((prevHistory) => [...prevHistory, { type: MESSAGE_TYPE.CLIENT, text: message }]);
		set_waiting_for_response(true);
		ws?.send(JSON.stringify({ type: "query", content: message }));
	};

	useEffect(() => {
		const socket = new WebSocket(`${process.env.REACT_APP_GATEWAY as string}${window.location.pathname}`);

		socket.addEventListener("open", (event) => {
			set_ws(socket);
		});

		socket.addEventListener("message", (event) => {
			if (typeof event.data === "string") {
				const parsed_message: string = message_parser(event.data);

				set_message_history((prevHistory) => [...prevHistory, { type: MESSAGE_TYPE.SERVER, text: parsed_message }]);
				set_waiting_for_response(false);
			}
		});

		socket.addEventListener("error", (event) => {
			set_ws(null);
			set_waiting_for_response(false);
		});

		socket.addEventListener("close", (event) => {
			set_ws(null);
			set_waiting_for_response(false);
		});

		return () => {
			socket.close();
		};
	}, []);

	const chat_style: string =
		"relative flex flex-col items-center text-off-white py-8 mx-auto w-full sm:w-11/12 md:w-5/6 lg:w-3/4 xl:w-2/3 2xl:w-7/12 max-w-7xl";

	return (
		<div className={chat_style}>
			{ws ? (
				<ChatMessages message_history={message_history} waiting_for_response={waiting_for_response} />
			) : (
				<h3 className="flex h-full items-center justify-center text-center text-sm-header font-bold text-off-white">
					Lost connection. Please refresh this page.
				</h3>
			)}
			<InputBox
				onClick={(message: string, upload_files: Array<LocalFile>) => on_send(message, upload_files)}
				isDisabled={waiting_for_response}
			/>
		</div>
	);
}

export default Chat;
