import Chat from "./pages/chat";
// import Menu from "./partials/menu";

import "./App";

function App() {
	return (
		<div className="flex h-screen w-screen flex-row bg-dark-gray font-poppins">
			{/* <Menu /> */}
			<Chat />
		</div>
	);
}

export default App;
